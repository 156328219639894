import styled from "styled-components";
import { colors, fonts } from "styles";

export const Container = styled.div`
  margin-left: ${(props) => (props.marginLeft ? "30px" : "0px")};
  margin-right: ${(props) => (props.marginRight ? "30px" : "0px")};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.span`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: ${colors.white};
  margin-bottom: 10px;
`;

export const Field = styled.input`
  border: 1px solid #e7e7e7;
  height: ${(props) => (props.height ? props.height : "40px")};
  width: ${(props) => (props.width ? props.width : "100%")};
`;
