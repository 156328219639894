import React from "react";
import { useIntl } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Container, Title, Card, Logo } from "./styles";
import messages from "./messages";
import useWindowSize from "hooks/useWindowSize";
import LogoAgroInovacao from "assets/logoAgroInovacao.png";
import LogoAgs from "assets/logoAgs.png";
import LogoBanriTech from "assets/logoBanriTech.jpg";
import LogoCriatec from "assets/logoCriatec.png";
import LogoEspm from "assets/logoEspm.png";
import LogoGaas from "assets/logoGaas.jpeg";
import LogoiColab from "assets/logoiColab.png";
import LogoInovaAmazonia from "assets/logoInovaAmazonia.jpg";
import LogoInovAtiva from "assets/logoInovAtiva.png";
import LogoMelhoramentoGenetico from "assets/logoMelhoramentoGenetico.jpeg";
import LogoPibernat from "assets/logoPibernat.png";
import LogoRandon from "assets/logoRandon.png";
import LogoSebrae from "assets/logoSebrae.jpg";
import LogoStartupRS from "assets/logoStartupRS.png";
import LogoTranscontinental from "assets/logoTrancontinental.png";
import LogoCaldeira from "assets/logoCaldeira.png";
import LogoXnode from "assets/logoXnode.png";

export default function Partners() {
  const [width] = useWindowSize();
  const responsive = {
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsive2 = {
    mobile: {
      breakpoint: { max: Infinity, min: 991 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const { formatMessage } = useIntl();

  const partnersList = [
    {
      name: "Criatec",
      logo: LogoCriatec,
    },
    {
      name: "Startup RS",
      logo: LogoStartupRS,
    },
    {
      name: "Transcontinental",
      logo: LogoTranscontinental,
    },
    {
      name: "Pibernat",
      logo: LogoPibernat,
    },
    {
      name: "Espm",
      logo: LogoEspm,
    },
    {
      name: "Agro Inovação",
      logo: LogoAgroInovacao,
    },
    {
      name: "Ags",
      logo: LogoAgs,
    },
    {
      name: "BanriTech",
      logo: LogoBanriTech,
    },
    {
      name: "Gaas",
      logo: LogoGaas,
    },
    {
      name: "iColab",
      logo: LogoiColab,
    },
    {
      name: "Inova Amazônia",
      logo: LogoInovaAmazonia,
    },
    {
      name: "InovAtiva",
      logo: LogoInovAtiva,
    },
    {
      name: "Melhoramento Genetico",
      logo: LogoMelhoramentoGenetico,
    },
    {
      name: "Randon",
      logo: LogoRandon,
    },
    {
      name: "Sebrae",
      logo: LogoSebrae,
    },
    {
      name: "Caldeira",
      logo: LogoCaldeira,
    },
    {
      name: "Xnode",
      logo: LogoXnode,
    },
  ];

  return (
    <Container>
      <Title>{formatMessage(messages.title)}</Title>

      {width < 991 && (
        <Carousel responsive={responsive} arrows={false} autoPlay infinite>
          {partnersList.map((partner, index) => (
            <Card first={index === 0} key={index}>
              <Logo src={partner.logo} alt={partner.name} />
            </Card>
          ))}
        </Carousel>
      )}

      {width > 992 && (
        <Carousel responsive={responsive2} arrows={false} autoPlay infinite>
          {partnersList.map((partner, index) => (
            <Card first={index === 0} key={index}>
              <Logo src={partner.logo} alt={partner.name} />
            </Card>
          ))}
        </Carousel>
      )}
    </Container>
  );
}
