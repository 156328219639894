import React from 'react';

import { GreenStripe, Container, ContentContainer, Notebook, TextContainer, Title, SubTitle, Text, ButtonContainer, NotebookContainer } from './styles';

import messages from './messages';
import { useIntl } from 'react-intl';
import Button from 'components/Button';

import notebook from 'assets/notebook.png';
export default function JoinUs({ id }) {

    const { formatMessage } = useIntl();

    return (
      <Container id={id}>
        <GreenStripe />
        <ContentContainer>
          <NotebookContainer>
            <Notebook src={notebook} />
          </NotebookContainer>

          <TextContainer>
            <Title>{formatMessage(messages.title)}</Title>

            <SubTitle>{formatMessage(messages.subtitle)}</SubTitle>

            <Text>{formatMessage(messages.description)}</Text>
            <ButtonContainer>
                <Button background={"green"} onClick={e => window.location.href='https://app.agriconbusiness.com/welcome-farmer'}>
                  {formatMessage(messages.ruralProductor)}
                </Button>
                <Button background={"green"} onClick={e => window.location.href='https://app.agriconbusiness.com/welcome-buyer'}>
                  {formatMessage(messages.globalBuyer)}
                </Button>
            </ButtonContainer>
          </TextContainer>
        </ContentContainer>
        <GreenStripe />
      </Container>
    );
}