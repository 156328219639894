import styled, { css } from 'styled-components';
import { colors, fonts } from 'styles';

export const Button = styled.button`
  font-family: ${fonts.primary};
  font-size: ${props => props.textSize ? props.textSize : '16px'};
  font-weight: ${props => props.textWeight ? props.textWeight : 'normal'};
  width: 100%;
  background: ${({ background }) => colors[background] || colors.primary};
  color: ${({ color }) => colors[color] || colors.white};
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover{
    background: ${({ background }) => colors[background] || colors.greenDark};
  }

  ${({ disabled }) =>
        disabled &&
        css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;
