import React, { useState } from "react";
import { useIntl } from "react-intl";

import computer from "assets/computer.png";
import { Dash } from "../About/styles";
import messages from "./messages";
import {
  MainContainer,
  BgBlockchain,
  LeftContainer,
  RightContainer,
  ComputerImage,
  Container,
  ContentContainer,
  DeliveryText,
  DescriptionText,
  TechnologyText,
  Title,
  GreenerStripe,
  LinkButton,
  DialogContent,
  DialogText,
  GreenVerticalStripe,
  DialogTextContainer,
  GreyVerticalStripe,
} from "./styles";
import Button from "components/Button";
import useWindowSize from "hooks/useWindowSize";
import { GreyDash } from "../ContactUs/styles";
import { GreenStripe } from "../JoinUs/styles";
import Table from "./Table";
import { Dialog } from "@material-ui/core";

export default function Blockchain({ id }) {
  const { formatMessage } = useIntl();
  const [width] = useWindowSize();

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <BgBlockchain id={id}>
        <MainContainer>
          <Title>{formatMessage(messages.title)}</Title>

          <Dash />

          <ContentContainer>
            <LeftContainer>
              <DescriptionText
                dangerouslySetInnerHTML={{
                  __html: formatMessage(messages.whatIs),
                }}
              />
              <DeliveryText
                dangerouslySetInnerHTML={{
                  __html: formatMessage(messages.delivery),
                }}
              />
              <TechnologyText>
                {formatMessage(messages.tecnologyFirst)}
                {formatMessage(messages.tecnologySecond)}
              </TechnologyText>

              {width > 991 && (
                <Button
                  onClick={(e) =>
                    (window.location.href = "https://app.agriconbusiness.com/")
                  }
                >
                  {formatMessage(messages.tryFree)}
                </Button>
              )}
            </LeftContainer>

            <RightContainer>
              <ComputerImage src={computer} />
            </RightContainer>
          </ContentContainer>
        </MainContainer>

        {width < 991 && (
          <React.Fragment>
            <GreyDash />
            <GreenStripe />
            <GreenerStripe />
          </React.Fragment>
        )}
        <Container id={"benefits"}>
          <Table />
        </Container>

        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogContent>
            <GreenVerticalStripe />
            <GreyVerticalStripe />
            <DialogTextContainer>
              <DialogText
                dangerouslySetInnerHTML={{
                  __html: formatMessage(messages.blockchainDescription),
                }}
              />
            </DialogTextContainer>
          </DialogContent>
        </Dialog>
      </BgBlockchain>
    </React.Fragment>
  );
}
