import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'partners.text.title',
        defaultMessage: 'Parceiros',
    },
});

export default messages;

