import React from 'react';

import * as s from './styles';

export default function Spinner ({ ...props }) {
  return (
    <s.Container {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </s.Container>
  );
}
