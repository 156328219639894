import { defineMessages } from 'react-intl';

const messages = defineMessages({
    address: {
        id: 'about.text.address',
        defaultMessage: 'Rua Monteiro Lobato 422 - Sala 01 - Bairro Partenon - Porto Alegre',
    },
    copyright: {
        id: 'footer.text.copyright',
        defaultMessage: '2020 Agricon Busines. Todos os direitos reservados. ',
    },
    greeting: {
    	id: 'footer.text.greeting',
    	defaultMessage: 'Olá! Visitei o site da Agricon e gostaria de saber mais informações.'
    }
});

export default messages;

