import styled from "styled-components";
import { colors, fonts, device } from "styles";

import ContactUs from "assets/contactUs.png";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 56px 10%;
  justify-content: space-between;
  background: linear-gradient(
      180deg,
      #000000 -24.17%,
      rgba(45, 45, 45, 0.65) 39.17%,
      rgba(45, 45, 45, 0.15) 97.83%
    ),
    url(${ContactUs});
  background-size: cover;

  @media ${device.untilLaptopM} {
    display: block;
  }
`;

export const LeftContainer = styled.div`
  width: calc(100% / 2);
  flex-direction: column;

  @media ${device.untilLaptopM} {
    width: 100%;
  }
`;

export const Title = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 40px;
  color: ${colors.white};

  @media ${device.untilLaptopM} {
    font-size: 30px;
  }
`;

export const Subtitle = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: ${colors.white};

  @media ${device.untilLaptopM} {
    font-size: 16px;
  }
`;

export const Text = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.white};
  margin-top: 10px;

  @media ${device.untilLaptopM} {
    margin-bottom: 60px;
  }
`;

export const WorkerImage = styled.img`
  margin-top: 67px;
  width: 100%;
  max-width: 445px;

  @media ${device.untilLaptopM} {
    display: none;
  }
`;
export const RightContainer = styled.div`
  margin-left: 2rem;
  flex-direction: column;

  @media ${device.untilLaptopM} {
    margin-left: 0;
  }
`;

export const FieldContainer = styled.div`
  display: ${(props) => (!props.first ? "block" : "flex")};
  flex-direction: row;
  margin-top: ${(props) => (!props.first ? "22px" : "0px")};
  width: 100%;
  & input {
    font-family: Roboto;
    padding: 10px 5px;
    transition: ease all 0.3s;
    &:focus {
      box-shadow: 0 0 3px #00bd00;
    }
  }
  @media ${device.untilLaptopM} {
    display: block;
    margin-top: 0;

    &:nth-child(3) {
      margin-top: 10px;
    }
    &:nth-child(4) {
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }
`;

export const Dash = styled.div`
  display: flex;
  width: 100%;
  height: 24px;
`;

export const GreyDash = styled(Dash)`
  background: ${colors.greyDark};
`;

export const GreyerDash = styled(Dash)`
  background: ${colors.silverChalice};
  width: 133px;
  height: 6px;
  margin: 13px 0 23px 0;
`;

export const FirstInputContainer = styled.div`
  width: 34%;
  margin-right: 30px;

  @media ${device.untilLaptopM} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
export const LastInputContainer = styled.div`
  width: 60%;

  @media ${device.untilLaptopM} {
    width: 100%;
  }
`;

export const InputColumn = styled.div`
  display: inline-block;

  @media ${device.untilLaptopM} {
    margin-top: 10px;
  }

  &:nth-child(1) {
    width: calc(100% / 2 - 30px);
    display: inline-block;
    margin-right: 15px;

    @media ${device.untilLaptopM} {
      width: 100%;
    }
  }

  &:nth-child(2) {
    width: calc(100% / 2);
    display: inline-block;
    margin-left: 15px;
    margin-bottom: 25px;

    @media ${device.untilLaptopM} {
      width: calc(100% / 2 - 30px);
      margin-right: 30px;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  &:nth-child(3) {
    width: calc(100% / 3 - 20px);
    display: inline-block;
    margin-right: 30px;

    @media ${device.untilLaptopM} {
      width: calc(100% / 2);
      margin-right: 0;
    }
  }

  &:nth-child(4) {
    width: calc(100% / 3 - 20px);
    display: inline-block;

    @media ${device.untilLaptopM} {
      width: calc(100% / 2 - 30px);
    }
  }

  &:nth-child(5) {
    width: calc(100% / 3 - 20px);
    display: inline-block;
    margin-left: 30px;

    @media ${device.untilLaptopM} {
      width: calc(100% / 2);
    }
  }
`;

export const InputColumnMid = styled.div`
  width: calc(100% / 3);

  &:nth-child(2) {
    margin: 0 30px;
  }
`;
