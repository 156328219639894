import { defineMessages } from 'react-intl';

const messages = defineMessages({
    digitalPlatform: {
        id: 'header.text.digitalPlatform',
        defaultMessage: 'Uma Plataforma Blockchain que revoluciona e cria uma nova forma de comercialização de produtos agrícolas, conectando produtores rurais a compradores globais.',
    },
    watchVideo: {
        id: 'header.button.watchVideo',
        defaultMessage: 'Assista o vídeo',
    }
});

export default messages;
