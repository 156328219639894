import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "blockchain.text.title",
    defaultMessage: "A Plataforma Blockchain",
  },
  whatIs: {
    id: "blockchain.text.whatIs",
    defaultMessage:
      "É muito simples, o produtor rural registra seu <b>LOTE (interesse de venda)</b>, o comprador global registra sua <b>LOI (interesse de compra)</b> Quando nossa tecnologia recebe essas informações, ele as cruza e conecta as pontas. A equipe Agricon através de parceiros logísticos e operacionais gerencia todo o processo de retirada do produto da propriedade rural até a entrega ao comprador global. ",
  },
  delivery: {
    id: "blockchain.text.delivery",
    defaultMessage:
      "A Agricon entrega aos seus clientes uma solução completa, que assegura todos os detalhes envolvidos em uma comercialização de produtos agrícolas.",
  },
  tecnologyFirst: {
    id: "blockchain.text.tecnologyFirst",
    defaultMessage: "Com nossa tecnologia ",
  },
  blockchain: {
    id: "blockchain.text.blockchain",
    defaultMessage: "blockchain",
  },
  tecnologySecond: {
    id: "blockchain.text.tecnologySecond",
    defaultMessage:
      " agregamos rastreabilidade, certificação de origem, transparência, segurança e simplicidade em todas as suas negociações.",
  },
  tryFree: {
    id: "blockchain.button.tryFree",
    defaultMessage: "Cadastre-se",
  },
  blockchainDescription: {
    id: "blockchain.text.blockchainDescription",
    defaultMessage:
      '<span style="color: #FFFFFF; font-size: 25px"><b>Blockchain</b></span> é uma rara combinação de elementos como Criptografia, Computação Distribuída e Teoria dos Jogos. Permite o registro distribuído de operações ao longo do tempo, e para a Agricon trará a segurança aos envolvidos sobre cada passo do produto, do produtor até a entrega.',
  },
});

export default messages;
