import { defineMessages } from 'react-intl';

const messages = defineMessages({
    rastreabilityFidedigna: {
        id: 'blockchain.text.rastreabilityFidedigna',
        defaultMessage: 'Rastreabilidade fidedigna do produtor ao comprador.',
    },
    tecnicalVisit: {
        id: 'blockchain.text.tecnicalVisit',
        defaultMessage: 'Visita técnica de validação e análise na propriedade rural.',
    },
    blockChainTecnology: {
        id: 'blockchain.text.blockChainTecnology',
        defaultMessage: 'Tecnologia Blockchain.',
    },
    lowerIntermediaries: {
        id: 'blockchain.text.lowerIntermediaries',
        defaultMessage: 'Diminuição dos nº de intermediários, alcançando novos elos da cadeia. ',
    },
    transparency: {
        id: 'blockchain.text.transparency',
        defaultMessage: 'Transparência',
    },
    directConnectionProductors: {
        id: 'blockchain.text.directConnectionProductors',
        defaultMessage: 'Conexão direta entre produtores e compradores.',
    },
    eficiency: {
        id: 'blockchain.text.eficiency',
        defaultMessage: ' Eficiência e segurança.',
    },
    compliance: {
        id: 'blockchain.text.compliance',
        defaultMessage: 'Compliance qualificado do comprador global.',
    },
    sustainableDevelopment: {
        id: 'blockchain.text.sustainableDevelopment',
        defaultMessage: 'Desenvolvimento de uma cadeia sustentável de comercializações.',
    },
});

export default messages;