import styled from 'styled-components';
import { colors, fonts, device } from 'styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10%;
`

export const AboutTitle = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    color: ${colors.greenDark};
    margin: 105px 0 10px 0;

    @media ${device.untilLaptopM} { 
      margin: 50px 0 10px 0;
      font-size: 20px;
    }
`

export const Dash = styled.div`
    width: ${props => props.width || '133px'};
    height: 6px;
    background: ${props => props.color || colors.greyDark};
    border-radius: 100px;
    margin: 0 0 20px 0;

    
    @media ${device.untilLaptopM} {
      width: 50px;
      height: 5px;
    }
`

export const AboutText = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: ${colors.greyDark};

    @media ${device.untilLaptopM} { 
      font-size: 14px;
    }
`

export const CardsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 60px 0;

    @media ${device.untilLaptopM} { 
      display: block;
      padding-bottom: 20px;
    }
`
export const Card = styled.div`
    width: 350px;
    min-height: 304px;
    background: ${colors.white};
    border: 1px solid #C6C6C6;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;

    &:first-child{
      margin-right: 30px;

      @media ${device.untilLaptopM} { 
        margin-right: 0;
      }
    }

    &:last-child{
      margin-left: 30px;

      @media ${device.untilLaptopM} { 
        margin-left: 0;
      }
    }

    @media ${device.fromLaptopL} { 
      width: calc(100% / 3 - 30px);
      min-height: auto;
    }

    @media(max-width: 991px){
      margin: 0 auto 20px auto;
      height: 100%;
      min-height: 100%;
      width: 100%;
    }
`

export const CardTitle = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: ${colors.dorado};

    @media ${device.untilLaptopM} { 
      font-size: 20px;
    }
`

export const CardTitleWhiteGreen = styled.span`
    background-color: ${colors.apple};
    color: ${colors.white};
    padding-left: 4px;
    padding-right: 4px;
`

export const CardText = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #8D8D8D;
    padding-top: 13px;

    @media ${device.untilLaptopM} { 
      font-size: 14px;
    }
`

export const WorkerContainer = styled.div`
    display: flex;
    align-items: center;
`

export const WorkerTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 26px;

    @media(max-width: 991px){
     padding-left: 0;
    }
`

export const WorkerPicture = styled.img`
    min-width: 425px;
    height: 282px;
    box-shadow: -40px 40px 0px -20px ${colors.apple};

    @media(max-width: 991px){
      display: none;
    }
`

export const WorkerPictureMobile = styled.img`
    display: none;

    @media(max-width: 991px){
      display: block;
      margin: 20px 0 20px 20px;
      max-width: 93%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      box-shadow: -40px 40px 0px -20px ${colors.apple};
      order: 2;
      
    }

    @media(max-width: 425px){
      box-shadow: -32px 32px 0px -20px ${colors.apple};
    }
`

export const FieldBornText = styled.p`
    font-family: ${fonts.primary};
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    margin-bottom: 11px;

    @media ${device.untilLaptopM} { 
      order: 1;
      font-size: 14px;
    }
`
export const FruitsText = styled(AboutText)`
    font-style: italic;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: ${colors.apple};
    margin-top: 28px;
    
    @media ${device.untilLaptopM} { 
      font-size: 20px;
      order: 3;
    }
`