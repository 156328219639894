import React from 'react';
import { useIntl } from 'react-intl';
import { colors } from 'styles';

import messages from './messages';
import { AboutText, AboutTitle, Card, CardsContainer, CardText, CardTitle, CardTitleWhiteGreen, Container, Dash, FieldBornText, FruitsText, WorkerContainer, WorkerPicture, WorkerPictureMobile, WorkerTextContainer } from './styles';
import useWindowSize from 'hooks/useWindowSize';
import worker from 'assets/worker.jpg';

export default function About() {
    const [width] = useWindowSize();
    const { formatMessage } = useIntl();
    return (
        <React.Fragment>
            <Container>
                <AboutTitle>
                    {formatMessage(messages.aboutTitle)}
                </AboutTitle>

                <Dash />

                <AboutText dangerouslySetInnerHTML={{ __html: formatMessage(messages.aboutText) }} />

                <CardsContainer>
                    <Card>
                        <CardTitle>
                            {formatMessage(messages.our)} <CardTitleWhiteGreen>{formatMessage(messages.missionTitle)}</CardTitleWhiteGreen>
                        </CardTitle>
                        <CardText>
                            {formatMessage(messages.ourMissionText)}
                        </CardText>
                    </Card>

                    <Card>
                        <CardTitle>
                            {formatMessage(messages.our)} <CardTitleWhiteGreen>{formatMessage(messages.visionTitle)}</CardTitleWhiteGreen>
                        </CardTitle>
                        <CardText>
                            {formatMessage(messages.ourVisionText)}
                        </CardText>
                    </Card>

                    <Card>
                        <CardTitle>
                            {formatMessage(messages.ours)} <CardTitleWhiteGreen>{formatMessage(messages.valuesTitle)}</CardTitleWhiteGreen>
                        </CardTitle>
                        <CardText>
                            {formatMessage(messages.ourValuesText)}
                        </CardText>
                    </Card>
                </CardsContainer>

                <WorkerContainer>
                    <WorkerPicture src={worker} />

                    <WorkerTextContainer>
                        <WorkerPictureMobile src={worker}/>
                        <FieldBornText>
                            {formatMessage(messages.fieldBornText)}
                        </FieldBornText>

                        {width > 992 && <Dash color={colors.greenDark} width={'138px'} /> }
                      
                        <FruitsText>
                            {formatMessage(messages.fruitsText)}
                        </FruitsText>
                    </WorkerTextContainer>
                </WorkerContainer>
            </Container>
        </React.Fragment>
    )
}