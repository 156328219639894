import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import globalReducer from "./reducer";

const appReducer = combineReducers({
  language: globalReducer,
});

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? composeWithDevTools({ name: "agricon-site" })
    : null || compose;

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
