const colors = {
  apple: "#47AC34",
  green: "#00BD00",
  greenDark: "#2E592E",
  greyDark: "#2D2D2D",
  camarone: "#005113",
  camarone2: "#005D16",
  dorado: "#565656",
  white: "#FFFFFF",
  mercury: "#E5E5E5",
  fuzzyWuzzyBrown: "#C64E4E",
  silverChalice: "#9D9D9D",
  alto: "#D5D5D5",
  silver: "#C4C4C4",
  silver2: "#C9C9C9",
  silver3: "#9E9E9E",
  silver4: "#A7A7A7",
  silver5: "#9F9F9F",
  silver6: "#F7F7F7",
  boulder: "#7D7D7D",
  black: "#000000",
  fordSilver: "#BEBEBE",
};

export default {
  ...colors,
  primary: colors.apple,
  secondary: colors.camarone2,
  danger: colors.fuzzyWuzzyBrown,
  defaultSVG: colors.silverChalice,
  defaultText: colors.dorado,
  defaultTitle: colors.camarone2,
  defaultInputBorder: colors.alto,
  defaultCardBorder: colors.silver,
};
