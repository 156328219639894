import React from 'react';

import * as s from './styles';

export default function Container({ children, ...props }) {
    return (
        <s.Container {...props}>
            {children}
        </s.Container>
    );
}
