import styled from 'styled-components';
import { colors, fonts, device } from 'styles';

export const GreenStripe = styled.div`
    display: flex;
    width: 100%;
    height: 24px;
    background: ${colors.greenDark};
`

export const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    background-color: ${colors.greyDark};
    padding: 32px 10%;

    @media(max-width: 991px){
        flex-direction: column;
    }
`

export const NotebookContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`

export const Notebook = styled.img`
    width: 100%;
    max-width: 560px;
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 0;
`

export const Title = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: 700;
    font-size: 3.125rem;
    line-height: 40px;
    color: ${colors.white};

    @media ${device.untilLaptopM} { 
      font-size: 30px;
    }
`

export const SubTitle = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 1.625rem;
    line-height: 40px;
    color: #EBEBEB;
    margin-top: 16px;

    @media ${device.untilLaptopM} { 
      font-size: 18px;
    }
`

export const Link = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: ${colors.white};
  text-align: center;
  min-width: 360px;
  
  text-decoration: none;

  button {
        height: 60px;
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 40px;
        width: 100%;
    }
`

export const Text = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 30px;
    color: ${colors.white};
    margin-top: 12px;

    @media ${device.untilLaptopM} { 
      font-size: 14px;
    }
`
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 47px;

    button {
        padding: 10px;
        width: 50%;
        height: 60px;
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 40px;

        &:last-child {
            margin-left: 30px;
        }
    }
    
    @media(max-width: 991px){
        flex-direction: column;
        margin-top: 33px;

        button {
            width: 100%;
            margin: 0;

            &:last-child {
                margin-left: 0;
                margin-top: 20px;
            }
        };
    }
`