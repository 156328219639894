import React from 'react';
import VideoHolder from 'components/VideoHolder';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Container from 'components/Container';
import About from './About';
import Blockchain from './Blockchain';
import Partners from './Partners';
import JoinUs from './JoinUs';
import ContactUs from './ContactUs';

export default function LandingPage() {


    return (
        <Container>
            <Header />

            <VideoHolder />
            
            <About />

            <Blockchain id={'platform'}/>

            <Partners />

            <JoinUs id={'join'}/>

            <ContactUs id={'contact'} />

            <Footer />
        </Container>
    )
}