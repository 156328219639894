import styled from "styled-components";
import { colors, fonts, device } from "styles";

export const TabContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;

  @media ${device.untilLaptopM} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const TabHeader = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  color: ${(props) => (props.color ? props.color : colors.white)};
  height: 82px;
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;

  @media ${device.untilLaptopM} {
    height: 60px;
    padding: 10px;
  }
`;

export const TabRow = styled.div`
  display: flex;
  padding: 7.5px 0;

  @media ${device.untilLaptopM} {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
`;

export const TabCell = styled.div`
  width: 390px;
  height: 240px;
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  margin: 0 15px;
  padding: 0 15px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};

  color: ${(props) => (props.color ? props.color : colors.white)};

  @media ${device.untilLaptopL} {
    width: 300px;
  }

  @media ${device.untilLaptop} {
    width: 280px;
  }

  @media ${device.untilLaptopM} {
    height: 77px;
    margin: 5.5px 0;
    width: 100%;
    padding: 30px 20%;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const TabLeft = styled.div`
  width: 100%;
  justify-content: center;

  @media (max-width: 991px) {
  }
`;

export const TabCellLeft = styled(TabCell)`
  background-color: ${(props) => (props.color ? props.color : colors.silver6)};
  color: ${colors.dorado};

  &:last-child {
    height: 82px;
  }
`;

export const TabRight = styled.div`
  width: 100%;
  justify-content: center;

  @media (max-width: 991px) {
  }
`;

export const TabCellRight = styled(TabCell)`
  background-color: ${(props) => (props.color ? props.color : "#66AF58")};
  color: ${colors.white};
`;
