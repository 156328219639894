import React from 'react';

import Spinner from '../Spinner';

import * as s from './styles';

export default function Button({ loading, disabled, children, ...props }) {
    const Loader = loading && <Spinner color='white' size='insideButton' />;
    return (
        <s.Button disabled={disabled || loading} {...props}>
            {Loader || children}
        </s.Button>
    );
}
