import React from "react";
import { useIntl } from "react-intl";

import {
  Container,
  LeftContainer,
  LinkButton,
  Logo,
  RightContainer,
  Text,
} from "./styles";
import messages from "./messages";
import whatsapp from "assets/iconWhatsapp.png";
import instagram from "assets/iconInstagram.png";
import facebook from "assets/iconFacebook.png";
import linkedin from "assets/iconLinkedin.png";
import youtube from "assets/iconYoutube.png";


export default function Footer() {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <LeftContainer>
        <Logo />
        <Text>{formatMessage(messages.address)}</Text>

        <Text>{formatMessage(messages.copyright)}</Text>
      </LeftContainer>
      <RightContainer>
        <LinkButton
          href={
            "https://api.whatsapp.com/send?phone=+5551991634737&text=" +
            formatMessage(messages.greeting)
          }
          target={"_blank"}
        >
          <img src={whatsapp} alt="Whats" />
        </LinkButton>

        <LinkButton
          href={"https://www.facebook.com/Agricon-Business-101944821807357"}
          target={"_blank"}
        >
          <img src={facebook} alt="Face" />
        </LinkButton>

        <LinkButton
          href={"https://www.instagram.com/agriconbusiness/"}
          target={"_blank"}
        >
          <img src={instagram} alt="Insta" />
        </LinkButton>

        <LinkButton
          href={
            "https://www.linkedin.com/company/agricon-business/?viewAsMember=true"
          }
          target={"_blank"}
        >
          <img src={linkedin} alt="Linkedin" />
        </LinkButton>
        <LinkButton
          href={"https://www.youtube.com/channel/UC9PAa-T7OgJ3GdULotUgMXg"}
          target={"_blank"}
        >
          <img src={youtube} alt="Youtube" />
        </LinkButton>
      </RightContainer>
    </Container>
  );
}
