import React from "react";
import { TabCell, TabContainer, TabRow } from "./styles";
import messages from "./messages";
import { useIntl } from "react-intl";

export default function Table() {
  const { formatMessage } = useIntl();
  return (
    <TabContainer>
      <TabRow>
        <TabCell backgroundColor={"#2F8135"}>{formatMessage(messages.rastreabilityFidedigna)}</TabCell>
        <TabCell backgroundColor={"#47AC34"}>{formatMessage(messages.tecnicalVisit)}</TabCell>
        <TabCell backgroundColor={"#72BF5E"}>{formatMessage(messages.blockChainTecnology)}</TabCell>
      </TabRow>

      <TabRow>
        <TabCell backgroundColor={"#2F8135"}>{formatMessage(messages.lowerIntermediaries)}</TabCell>
        <TabCell backgroundColor={"#47AC34"}>{formatMessage(messages.transparency)}</TabCell>
        <TabCell backgroundColor={"#72BF5E"}>{formatMessage(messages.directConnectionProductors)}</TabCell>
      </TabRow>

      <TabRow>
        <TabCell backgroundColor={"#2F8135"}>{formatMessage(messages.eficiency)}</TabCell>
        <TabCell backgroundColor={"#47AC34"}>{formatMessage(messages.compliance)}</TabCell>
        <TabCell backgroundColor={"#72BF5E"}>{formatMessage(messages.sustainableDevelopment)}</TabCell>
      </TabRow>
    </TabContainer>
  );
}
