import React from "react";
import { useIntl } from "react-intl";
import PlayCircleOutlineSharpIcon from "@material-ui/icons/PlayCircleOutlineSharp";

import { Container, InnerContainer, Text, WatchButton } from "./styles";
import messages from "./messages";
import { colors } from "styles";
import { useSelector } from "react-redux";

export default function VideoHolder() {
  const { formatMessage } = useIntl();
  const { current } = useSelector((state) => state.language);

  const getLink = () => {
    switch (current) {
      case "en":
        return "https://www.youtube.com/watch?v=APYBLktLb9w&ab_channel=OnFarm-AgriconBusiness";
      case "pt-br":
      default:
        return "https://youtu.be/jSxxh5FYuPg";
    }
  }

  return (
    <Container>
      <InnerContainer>
        <PlayCircleOutlineSharpIcon
          fontSize={"inherit"}
          htmlColor={colors.white}
          style={{ marginLeft: 30, fontSize: "3.5em" }}
        />
        <Text
          dangerouslySetInnerHTML={{
            __html: formatMessage(messages.digitalPlatform),
          }}
        />
      </InnerContainer>
      <a className="video" href={getLink()} target="_blank" rel="noreferrer">
        <WatchButton>{formatMessage(messages.watchVideo)}</WatchButton>
      </a>
    </Container>
  );
}
