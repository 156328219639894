import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'contactus.text.title',
        defaultMessage: 'Fale conosco',
    },
    subtitle: {
        id: 'contactus.text.subtitle',
        defaultMessage: 'Ficou com alguma dúvida?',
    },
    pleasure: {
        id: 'contactus.text.pleasure',
        defaultMessage: 'Será um grande prazer  ajudar.',
    },
    profile: {
        id: 'contactus.label.profile',
        defaultMessage: 'Selecione seu perfil',
    },
    buyer: {
        id: 'contactus.option.buyer',
        defaultMessage: 'Comprador'
    },
    farmer: {
        id: 'contactus.option.farmer',
        defaultMessage: 'Produtor'
    },
    name: {
        id: 'contactus.label.name',
        defaultMessage: 'Nome completo',
    },
    email: {
        id: 'contactus.label.email',
        defaultMessage: 'E-mail',
    },
    phone: {
        id: 'contactus.label.phone',
        defaultMessage: 'Telefone',
    },
    city: {
        id: 'contactus.label.city',
        defaultMessage: 'Cidade',
    },
    state: {
        id: 'contactus.label.state',
        defaultMessage: 'Estado',
    },
    country: {
        id: 'contactus.label.country',
        defaultMessage: 'Pais',
    },
    subject: {
        id: 'contactus.label.subject',
        defaultMessage: 'Assunto',
    },
    message: {
        id: 'contactus.label.message',
        defaultMessage: 'Mensagem',
    },
    sendMessage: {
        id: 'contactus.button.sendMessage',
        defaultMessage: 'Enviar mensagem',
    },
    sent: {
        id: 'contactus.message.sent',
        defaultMessage: 'Sua mensagem foi enviada com sucesso. A equipe Agricon entrará em contato'
    },
    error: {
        id: 'contactus.message.error',
        defaultMessage: 'Não foi possível enviar a sua mensagem. Por favor, tente novamente mais tarde.'
    }
});

export default messages;
