import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'joinus.link.title',
        defaultMessage: 'Cadastre-se',
    },
    subtitle: {
        id: 'joinus.text.subtitle',
        defaultMessage: 'Venha fazer parte da Agricon.',
    },
    description: {
        id: 'joinus.text.description',
        defaultMessage: 'Aqui você tem a oportunidade de comercializar produtos agrícolas globalmente.',
    },
    ruralProductor: {
        id: 'joinus.button.ruralProductor',
        defaultMessage: 'Produtor rural',
    },
    globalBuyer: {
        id: 'joinus.button.globalBuyer',
        defaultMessage: 'Comprador global',
    },
});

export default messages;
