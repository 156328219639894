import { Button } from 'components/Button/styles';
import styled from 'styled-components';
import { colors, fonts } from 'styles';

export const Container = styled.div`
    background: ${colors.greyDark};
    border: 1px solid ${colors.black};
    box-sizing: border-box;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 100px;
    margin: -50px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video:link, .video:visited {
        text-decoration: none;
    }

    @media(max-width: 1089px){
      height: auto;
      padding: 25px 20px;
    }

    @media(max-width: 991px){
      display: inline-block;
      margin: -110px 10% 0;
    }

`
export const InnerContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media(max-width: 991px){
      & svg{
        display: none;
      }
      p{
        margin: 0 auto;
        text-align: center;
      }
    }
`

export const Text = styled.p`
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.white};
    margin: 0 30px;
    max-width: 655px;

    @media(max-width: 991px){
      margin: 0 20px;
      font-size: 14px;
    }
`

export const WatchButton = styled(Button)`
    margin-right: 30px; 
    padding: 10px 0; 
    width: 190px;
    max-width: unset;
    min-width: 190px;

    @media(max-width: 991px){
      margin: 25px auto 0;

    }
`
