import React from 'react';
import { IntlProvider } from 'react-intl';

import './App.css';
import GlobalStyle from 'styles/global';
import LandingPage from 'views/LandingPage';
import ptBR from 'i18n/locales/pt-br.json';
import en from 'i18n/locales/en.json';
import { useSelector } from 'react-redux';

function App() {
  const { current } = useSelector((state) => state.language);

  const getMessages = () => {
    switch (current) {
      case 'en':
        return en;
      case 'pt-br': 
      default:
        return ptBR;
    }
  }

  return (
    <div>
      <IntlProvider
        locale={current}
        key={current}
        messages={getMessages()}
      >
        <GlobalStyle />
        <LandingPage />
      </IntlProvider>
    </div>
  );
}

export default App;
