import React from 'react';
import { Container, Field, Label } from './styles';

export default function TextField({ label, marginLeft, marginRight, width, height, value, onChange }) {

    return (
        <Container marginLeft={marginLeft} marginRight={marginRight}>
            <Label>
                {label}
            </Label>
            <Field width={width} height={height} value={value} onChange={onChange}/>
        </Container>
    )
}