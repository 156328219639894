import styled from "styled-components";
import { colors, fonts, device } from "styles";
import BgBlock from "assets/bgBlockchain.png";
import { Link } from "@material-ui/core";

export const Container = styled.div`
  height: 100%;
  flex: 1;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;

  button {
    width: 253px;
    height: 51px;
    margin-top: 46px;

    @media ${device.untilLaptopM} {
      width: 100%;
    }
  }
`;

export const MainContainer = styled(Container)`
  padding-top: 30px;
`;

export const BgBlockchain = styled.div`
  background-image: url(${BgBlock});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  margin-top: 90px;
  height: 100%;
  padding-bottom: 6%;

  @media ${device.untilLaptopM} {
    background-image: none;
    margin-top: 50px;
  }
`;

export const Title = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: ${colors.greenDark};
  margin-bottom: 17px;

  @media ${device.untilLaptopM} {
    font-size: 20px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  max-width: 542px;
  margin-right: 61px;

  @media ${device.untilLaptopM} {
    order: 2;
    margin: 49px 0 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: unset;
  }
`;

export const DescriptionText = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: ${colors.dorado};

  @media ${device.untilLaptopM} {
    font-size: 14px;
  }
`;

export const DeliveryText = styled(DescriptionText)`
  margin-top: 20px;
`;

export const TechnologyText = styled(DescriptionText)`
  margin-top: 20px;
`;

export const RightContainer = styled.div`
  width: 100%;
  justify-content: center;

  @media ${device.untilLaptopM} {
    order: 1;
    display: flex;
  }
`;

export const ComputerImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 513px;
  min-width: 329px;
  width: 100%;

  @media ${device.untilLaptopM} {
    height: 100%;
  }
`;

export const GreenerStripe = styled.div`
  display: flex;
  width: 100%;
  height: 24px;
  background: #70912c;
`;

export const LinkButton = styled(Link)`
  color: #47ac34 !important;
  width: auto !important;
  height: auto !important;
  margin-top: unset !important;
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 2px !important;
`;

export const DialogContent = styled.div`
  width: 350px;
  display: flex;
`;

export const DialogTextContainer = styled.div`
  padding: 33px 0;
  margin: 0px 46px 0 0;
`;

export const DialogText = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  background: #47ac34;
  padding: 33px 18px;
`;

export const GreenVerticalStripe = styled.div`
  min-width: 40px;
  background: #2e592e;
`;

export const GreyVerticalStripe = styled.div`
  min-width: 18px;
  background: #c6c6c6;
`;
