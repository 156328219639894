import { defineMessages } from 'react-intl';

const messages = defineMessages({
    aboutTitle: {
        id: 'about.text.aboutTitle',
        defaultMessage: 'Sobre a Agricon',
    },
    aboutText: {
        id: 'about.text.aboutText',
        defaultMessage: 'A <span style="color: #47AC34"><b>Agricon Business</b></span> é uma startup conecta o produtor rural brasileiro ao comprador global de forma direta, dinâmica e disruptiva. Prioriza a rastreabilidade, certificação de qualidade, segregação e transparência dos seus processos e produtos agrícolas comercializados, através de uma <span style="color: #47AC34"><b>Plataforma Blockchain</b></span>.',
    },
    our: {
        id: 'about.text.our',
        defaultMessage: 'Nossa',
    },
    missionTitle: {
        id: 'about.text.missionTitle',
        defaultMessage: 'missão',
    },
    ourMissionText: {
        id: 'about.text.ourMissionText',
        defaultMessage: 'é trazer ao agronegócio uma nova visão. Valorizando o trabalho incansável do produtor rural e entendendo a necessidade do comprador global, facilitando acessos e estreitando a cadeia.',
    },
    visionTitle: {
        id: 'about.text.visionTitle',
        defaultMessage: 'visão',
    },
    ourVisionText: {
        id: 'about.text.ourVisionText',
        defaultMessage: 'está em perpetuar uma rede comercial integrada de transações para diversos produtos agrícolas. Oferecer aos nossos clientes soluções que agregam tecnologia e sustentabilidade, com o objetivo de promover conexões transparentes, seguras e rentáveis.',
    },
    ours: {
        id: 'about.text.ours',
        defaultMessage: 'Nossos',
    },
    valuesTitle: {
        id: 'about.text.valuesTitle',
        defaultMessage: 'valores',
    },
    ourValuesText: {
        id: 'about.text.ourValuesText',
        defaultMessage: 'são inovação, valorização do homem do campo, comprometimento com as necessidades do comprador global, qualidade de produtos e processos, amor em todos os detalhes, Ética, respeito, discernimento e empatia;',
    },
    fieldBornText: {
        id: 'about.text.fieldBornText',
        defaultMessage: 'Nascemos no campo e, aprendemos na origem, passada de geração a geração, o verdadeiro amor e respeito por esse chão. Saber olhar uma lavoura em produção, e ver além da plantação, compreendendo intimamente o valor do tempo, o sol, a chuva, a energia que nos move em uma única direção: Produzir, respeitar, inovar e alcançar a evolução.',
    },
    fruitsText: {
        id: 'about.text.fruitsText',
        defaultMessage: 'Somos frutos de uma terra onde acreditar é a nossa esperança diária,  e é essa força que hoje se traduz a quem somos, a Agricon Business.',
    },
});

export default messages;

