import styled from "styled-components";
import { colors, fonts, device } from "styles";
import logoBusiness from "assets/logoAgriconBusiness.png";

export const Container = styled.div`
  display: flex;
  //flex-direction: column;
  padding: 37px 10%;
  background-color: ${colors.greenDark};

  @media ${device.untilLaptopM} {
    display: block;
  }
`;
export const LeftContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc(100% / 2);

  @media ${device.untilLaptopM} {
    width: 100%;
  }
`;

export const Logo = styled.div`
  width: 147px;
  height: 70px;
  margin-left: 7px;
  background: url(${logoBusiness});
`;

export const Text = styled.p`
  font-family: ${fonts.primary};
  font-size: 16px;
  line-height: 30px;
  color: ${colors.white};
  padding: 22px 0 0 0;
`;

export const RightContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: calc(100% / 2);

  @media ${device.untilLaptopM} {
    width: 100%;
    margin-top: 40px;
    justify-content: space-between;
  }
`;

export const LinkButton = styled.a`
  display: block;
  width: calc(100% / 2);

  img {
    display: block;
    margin: 0 auto;
    transition: ease all 0.3s;
    height: 25px;

    &:hover {
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }

  @media ${device.untilLaptopM} {
    width: auto;
  }
`;
