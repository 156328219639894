import { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore' // <- needed if using firestore

import firebaseConfig from '../firebase/config.json'
firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore

export default function useContact() {
	const [ sent, setSent ] = useState(false)
	const [ error, setError ] = useState(false)
	const to = 'contato@agriconbusiness.com'
	const bcc = 'tiago.costa@agriconbusiness.com, luciane@agriconbusiness.com, eduarda@agriconbusiness.com'
	const subject = 'Nova mensagem enviada pelo site'

	return {
		send: (message) => {
			firebase.firestore().collection('mail').add({
				to: to,
				bcc: bcc,
				message: {
					subject: subject,
					html: message
				}
			}).then(res => setSent(true) )
			.catch(e => setError(true))
		},
		sent,
		error
	}
}