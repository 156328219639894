import { actionTypes } from './actions';

export const languageByCountry = {
  // 'AR': 'es',
  'BR': 'pt-br',
  // 'CL': 'es',
  // 'CO': 'co',
  // 'MX': 'mx',
  // 'PE': 'pe',
  // 'CL': 'cl',
  // 'UY': 'uy',
  'EN': 'en',
}

export const countryByLanguage = {
  // 'AR': 'es',
  'pt-br': 'BR',
  // 'CL': 'es',
  // 'CO': 'co',
  // 'MX': 'mx',
  // 'PE': 'pe',
  // 'CL': 'cl',
  // 'UY': 'uy',
  'en': 'EN',
}

const initialState = {
  current: languageByCountry['BR'],
};

const languageChange = (state, action) => ({
  ...state,
  current: action.payload,
});

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE_CHANGE:
      return languageChange(state, action);
    default:
      return state;
  }
};

export default globalReducer;
