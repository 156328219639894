import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  Container,
  LeftContainer,
  Title,
  Subtitle,
  Text,
  RightContainer,
  FieldContainer,
  GreyDash,
  FirstInputContainer,
  LastInputContainer,
  InputColumn,
  GreyerDash,
} from "./styles";
import messages from "./messages";
import TextField from "components/TextField"
import Select from "components/Select"
import Button from "components/Button"
import useContact from 'modules/data/hooks/useContact'

const initialState = {
  profile: "",
  name: "",
  email: "",
  phone: "",
  city: "",
  state: "",
  country: "",
  subject: "",
  message: "",
};

export default function ContactUs({ id }) {
  const { formatMessage } = useIntl();
  const [contactDetails, setContactDetails] = useState(initialState);
  const [ loading, setLoading ] = useState(false)
  const contact = useContact()

  const sendMessage = () => {
    setLoading(true)
    contact.send(`
      <p><strong>De: ${contactDetails.profile} ${contactDetails.name} - ${contactDetails.phone}</p>
      <p><strong>Local:</strong> ${contactDetails.city}, ${contactDetails.state}, ${contactDetails.country}</p>
      <p><strong>Assunto:</strong> ${contactDetails.subject}</p>
      <p><strong>Mensagem:</strong></p>
      <p>${contactDetails.message}</p>
    `)
  };

  useEffect( () => {
    if(contact.sent) {
      alert(formatMessage(messages.sent))
      setContactDetails(initialState);
      setLoading(false)
    }
    if(contact.error) {
      alert(formatMessage(messages.error))
      setLoading(false)
    }
  }, [ contact.sent ])

  const isDisabled = () => {
    const {
      profile,
      name,
      email,
      phone,
      city,
      state,
      country,
      subject,
      message,
    } = contactDetails;
    return (
      !profile ||
      !name ||
      !email ||
      !phone ||
      !city ||
      !state ||
      !country ||
      !subject ||
      !message
    );
  };

  const setFormData = (key, event) => {
    setContactDetails((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  return (
    <React.Fragment>
      <Container id={id}>
        <LeftContainer>
          <Title>{formatMessage(messages.title)}</Title>

          <GreyerDash />

          <Subtitle>{formatMessage(messages.subtitle)}</Subtitle>

          <Text>{formatMessage(messages.pleasure)}</Text>
        </LeftContainer>
        <RightContainer>
          <FieldContainer first>
            <FirstInputContainer>
              <Select
                label={formatMessage(messages.profile)}
                value={contactDetails.profile}
                onChange={(event) => setFormData("profile", event)}
                options={[ formatMessage(messages.buyer), formatMessage(messages.farmer) ]}
              />
            </FirstInputContainer>
            <LastInputContainer>
              <TextField
                label={formatMessage(messages.name)}
                value={contactDetails.name}
                onChange={(event) => setFormData("name", event)}
              />
            </LastInputContainer>
          </FieldContainer>

          <FieldContainer>
            <InputColumn>
              <TextField
                label={formatMessage(messages.email)}
                value={contactDetails.email}
                onChange={(event) => setFormData("email", event)}
              />
            </InputColumn>

            <InputColumn>
              <TextField
                label={formatMessage(messages.phone)}
                value={contactDetails.phone}
                onChange={(event) => setFormData("phone", event)}
              />
            </InputColumn>

            <InputColumn>
              <TextField
                label={formatMessage(messages.city)}
                value={contactDetails.city}
                onChange={(event) => setFormData("city", event)}
              />
            </InputColumn>

            <InputColumn>
              <TextField
                label={formatMessage(messages.state)}
                value={contactDetails.state}
                onChange={(event) => setFormData("state", event)}
              />
            </InputColumn>

            <InputColumn>
              <TextField
                label={formatMessage(messages.country)}
                value={contactDetails.country}
                onChange={(event) => setFormData("country", event)}
              />
            </InputColumn>
          </FieldContainer>

          <FieldContainer>
            <TextField
              label={formatMessage(messages.subject)}
              value={contactDetails.subject}
              onChange={(event) => setFormData("subject", event)}
            />
          </FieldContainer>

          <FieldContainer>
            <TextField
              label={formatMessage(messages.message)}
              height={"100px"}
              value={contactDetails.message}
              onChange={(event) => setFormData("message", event)}
            />
          </FieldContainer>

          <FieldContainer>
            <Button
              style={{ padding: "10px", height: "54px" }}
              textSize={"20px"}
              textWeight={"bold"}
              onClick={sendMessage}
              disabled={isDisabled()}
              background={"green"}
            >
              {formatMessage(messages.sendMessage)}
            </Button>
          </FieldContainer>
        </RightContainer>
      </Container>
      <GreyDash />
    </React.Fragment>
  );
}
