import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  ConnectText,
  Container,
  GreenDash,
  HeaderSection,
  Link,
  Logo,
  LogoMobile,
  MainContainer,
  Menu,
  MenuButtonOpen,
  MenuButtonClose,
  Select,
  TopContainer,
  WelcomeText,
} from "./styles";
import messages from "./messages";
import Button from "components/Button";
import {
  MenuItem,
} from "@material-ui/core";
import useWindowSize from "hooks/useWindowSize";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { languageByCountry } from "store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { languageChange } from "store/actions";

export default function Header() {
  const { formatMessage } = useIntl();
  const [width] = useWindowSize();
  const [open, setOpen] = useState(false);
  const { current: language } = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(languageChange(event.target.value));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (partToScroll) => {
    if (partToScroll === "platform") {
      window.location.href = "https://app.agriconbusiness.com";
    } else {
      setAnchorEl(null);

      const element = document.getElementById(partToScroll);
      if (element) window.scrollTo(0, element.offsetTop);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <TopContainer>
          {width < 991 && <MenuButtonOpen onClick={handleClick} />}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuButtonClose onClick={handleClose} />
            <LogoMobile />
            <MenuItem onClick={() => handleClose("join")}>
              {formatMessage(messages.joinPlatform)}
            </MenuItem>
            <MenuItem onClick={() => handleClose("platform")}>
              {formatMessage(messages.accessPlatform)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(true);
              }}
            >
              {formatMessage(messages.changeLanguage)}{" "}
              <Select native open={open} value={language} onChange={handleChange}>
                {Object.keys(languageByCountry).map((obj) => (
                  <option value={languageByCountry[obj]}>{obj}</option>
                ))}
              </Select>
            </MenuItem>
            <MenuItem onClick={() => handleClose("contact")}>
              {formatMessage(messages.contactUs)}
            </MenuItem>
          </Menu>

          <Logo />

          {width > 992 && (
            <HeaderSection>
              <Link href={"https://app.agriconbusiness.com"}>
                {formatMessage(messages.accessPlatform)}
              </Link>
              <Link
                className="silent"
                href={"https://app.agriconbusiness.com/welcome-registration"}
              >
                <Button style={{ marginRight: "20px", padding: "10px 18px" }}>
                  {formatMessage(messages.joinPlatform)}
                </Button>
              </Link>
              <Select
                value={language}
                onChange={handleChange}
                IconComponent={({ className }) => (
                  <ExpandMore className={className} />
                )}
              >
                {Object.keys(languageByCountry).map((obj) => (
                  <MenuItem value={languageByCountry[obj]}>{obj}</MenuItem>
                ))}
              </Select>
            </HeaderSection>
          )}
        </TopContainer>
        <MainContainer>
          <GreenDash />
          <WelcomeText
            dangerouslySetInnerHTML={{
              __html: formatMessage(messages.welcome),
            }}
          />
          <ConnectText
            dangerouslySetInnerHTML={{
              __html: formatMessage(messages.connect),
            }}
          />
          <Button
            background={"white"}
            color={"greyDark"}
            style={{
              marginTop: "35px",
              padding: "10px 16px",
              maxWidth: "160px",
            }}
            onClick={() => handleClose("benefits")}
          >
            {formatMessage(messages.knowMore)}
          </Button>
        </MainContainer>
      </Container>
    </React.Fragment>
  );
}
