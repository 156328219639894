import styled from "styled-components";
import BannerFarmer from "../../assets/headerBackground.png";
import LogoAgricon from "assets/logoAgricon.svg";
import MenuIcon from "assets/menu.png";
import MenuIconClose from "assets/menuClose.png";
import { Menu as UiMenu, Select as SelectMui } from "@material-ui/core";

import { colors, device } from "styles";

export const Container = styled.div`
  flex: 1;
  background: #000000;
  background-image: linear-gradient(
      180deg,
      #000000 -24.17%,
      rgba(45, 45, 45, 0.65) 39.17%,
      rgba(45, 45, 45, 0.15) 97.83%
    ),
    url(${BannerFarmer});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 600px;
  max-height: 600px;
  padding: 32px 10%;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const Logo = styled.div`
  background-image: url(${LogoAgricon});
  width: 184px;
  height: 91px;
  background-repeat: no-repeat;
  user-select: none;

  @media ${device.untilLaptopM} {
    background-position: center;
    background-size: 146px;
    width: 100%;
    height: 75px;
    vertical-align: top;
  }
`;

export const LogoMobile = styled.div`
  background-image: url(${LogoAgricon});
  width: 184px;
  height: 91px;
  background-repeat: no-repeat;
  user-select: none;

  @media ${device.untilLaptopM} {
    display: inline-flex;
    width: calc(100% / 2);
    vertical-align: top;
    background-size: 145px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  height: 50px;

  .silent {
    text-decoration: none;
  }
`;

export const Link = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: ${colors.white};
  text-align: center;
  min-width: 160px;
  margin-right: 20px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GreenDash = styled.div`
  width: 31px;
  height: 5px;
  background: #6bac5f;
  border-radius: 100px;
  margin-top: 75px;

  @media ${device.untilLaptopM} {
    margin-top: 55px;
  }
`;
export const WelcomeText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #cccccc;
  margin-top: 20px;

  @media ${device.untilLaptopM} {
    font-size: 12px;
  }
`;

export const ConnectText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 54px;
  color: ${colors.white};
  margin-top: 10px;
  max-width: 635px;

  @media ${device.untilLaptopM} {
    font-size: 30px;
    line-height: 35px;
  }
`;

export const MenuButtonOpen = styled.div`
  background-image: url(${MenuIcon});
  background-repeat: no-repeat;
  width: 30px;
  height: 21px;
`;

export const MenuButtonClose = styled.div`
  background-image: url(${MenuIconClose});
  background-repeat: no-repeat;
  width: 184px;
  height: 91px;

  @media ${device.untilLaptopM} {
    width: calc(100% / 2 - 105px);
    height: 20px;
    display: inline-flex;
    vertical-align: top;
    margin-left: 10%;
  }
`;

export const Menu = styled(UiMenu)`
  background: rgb(29 28 28 / 80%);

  .MuiMenu-paper {
    width: 100%;
    max-width: 100%;
    padding: 30px 0;
    background-color: #005d16;
    color: ${colors.white};
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;

    li {
      display: block;
      margin: 2px 0;
      padding: 13px 0;
      background-color: #337d45;
      text-align: center;
      font-weight: 700;
    }
  }

  .MuiList-padding {
    padding-top: 2px;
  }
`;

export const Select = styled(SelectMui)`
  padding: 3px 0;
  border: 1px solid ${colors.white};
  border-radius: 5px;
  > div {
    padding-right: 35px !important;
    color: ${colors.white};
    font-size: 16px;
    font-weight: bold;
    padding-left: 17px;
  }
  &:focus {
    outline: none;
  }
  &.MuiInput-underline {
    &:before {
      border-radius: 5px;
    }
  }
  &.MuiInput-underline {
    &:after {
      border-bottom: 0;
    }
  }
  &.MuiSelect-select {
    padding-right: 10px;
  }
  & svg {
    fill: ${colors.white};
  }
  & select {
    padding-right: 55px !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding-left: 17px;
  }
`;
