import { defineMessages } from 'react-intl';

const messages = defineMessages({
    accessPlatform: {
        id: 'header.link.accessPlatform',
        defaultMessage: 'Acessar a plataforma',
    },
    joinPlatform: {
        id: 'header.button.joinPlatform',
        defaultMessage: 'Cadastre-se',
    },
    changeLanguage: {
        id: 'header.button.changeLanguage',
        defaultMessage: 'Mudar linguagem:',
    },
    contactUs: {
        id: 'header.button.contactUs',
        defaultMessage: 'Fale conosco',
    },
    welcome: {
        id: 'header.text.welcome',
        defaultMessage: 'Bem-vindo ao novo ecossistema <b>tecnológico</b>, <b>rastreável</b> e <b>sustentável</b>',
    },
    connect: {
        id: 'header.text.connect',
        defaultMessage: 'Conectando <span style="color: #6BAC5F;">produtores rurais</span> à <span style="color: #6BAC5F;">compradores globais</span>'
    },
    knowMore: {
        id: 'header.button.knowMore',
        defaultMessage: 'Saiba mais'
    }
});

export default messages;
