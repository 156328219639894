import styled from "styled-components";
import { colors, fonts, device } from "styles";

export const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 60px 10% 78px 10%;

  @media ${device.untilLaptopM} {
    padding: 60px 0 60px 10%;
  }

  li.react-multi-carousel-item:nth-child(7) > div,
  li.react-multi-carousel-item:nth-child(13) > div {
    padding: 0;
  }
`;

export const Title = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: ${colors.greyDark};
  margin-bottom: 20px;

  @media ${device.untilLaptopM} {
    font-size: 20px;
  }
`;

export const Text = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 30px;
  color: ${colors.white};
  margin-top: 12px;

  @media ${device.untilLaptopM} {
    font-size: 14px;
  }
`;

export const CardsContainer = styled.div`
  display: inline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  @media ${device.untilLaptopM} {
    display: inherit;
    overflow-y: scroll;
    flex-wrap: unset;
  }
`;

export const Card = styled.div`
  display: inline-flex;
  background-color: #fff;
  padding: 27px;
  align-content: space-between;
  margin-bottom: 30px;
  margin-right: 2%;
  width: calc(100% / 1 - 5%);
  height: 150px;

  &:nth-child(5),
  &:nth-child(10) {
    margin-right: 0;
  }

  & img {
    width: 100%;
    object-fit: contain;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;

    @media ${device.untilLaptopM} {
      width: 100%;
    }
  }

  @media ${device.untilLaptopM} {
    margin: 0 20px;
    width: calc(100% / 1 - 5%);
    padding: 13px;
  }
`;

export const Logo = styled.img``;
